import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION, INSIDE_THE_HEADER} from './../../constants/ActionTypes';
import {switchLanguage, toggleCollapsedNav} from 'actions/Setting';
import LanguageSwitcher from 'components/LanguageSwitcher/index';
import UserInfo from 'components/UserInfo';
import adminIcon from '../../../svg/admin_icon.svg';
import DatabaseLogo from "components/SidenavLogo/DatabaseLogo";
import {Route, Switch} from 'react-router-dom';
import DefaultLogo from "components/SidenavLogo/DefaultLogo";
import HealthTrafficLight from "components/HealthTrafficLight";
import IntlMessages from "appUtil/IntlMessages";
import Tooltip from "components/Tooltip";
import ToggleAutomaticDeployment from "components/ToggleAutomaticDeployment";
import AppLocale from "../../lngProvider";
import classNames from 'classnames';

class Header extends React.Component {
    onUserInfoSelect = () => {
        this.setState({
            userInfo: !this.state.userInfo
        })
    };

    onLangSwitcherSelect = (event) => {
        this.setState({
            langSwitcher: !this.state.langSwitcher
        })
    };

    handleRequestClose = () => {
        this.setState({langSwitcher: false, mailNotification: false, appNotification: false, searchBox: false});
    };

    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    constructor(props) {
        super(props);
        this.state = this.initState();
        this.optionsExpandedChanged = this.optionsExpandedChanged.bind(this);
        this.toggleAppBarExpanded = this.toggleAppBarExpanded.bind(this);
        this.setDatabaseLogo = this.setDatabaseLogo.bind(this);
    }

    initState() {
        let state = {
            mailNotification: false,
            langSwitcher: false,
            appNotification: false,
            userInfo: false,
            optionsExpanded: localStorage.getItem('optionsExpanded') === "1",
            appBarExpanded: localStorage.getItem('appBarExpanded') === "1"
        };
        if (this.state) {
            Object.assign(state, this.state);
        }
        state.urlPathName = this.props.history.location.pathname;
        state.logoDatabase = null;
        state.toggleAutomaticDeployEnabled = !/^\/app\/data\/[a-z0-9_\-]+\//.exec(state.urlPathName)
        return state;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.urlPathName !== this.props.history.location.pathname) {
            this.setState(this.initState());
        }
    }

    componentDidMount() {
        this.props.settingsEventBus.addListener('optionsExpanded-changed', this.optionsExpandedChanged);
        this.props.databaseEventBus.addListener('set-db-logo', this.setDatabaseLogo);
    }

    componentWillUnmount() {
        this.props.settingsEventBus.removeListener('optionsExpanded-changed', this.optionsExpandedChanged);
        this.props.databaseEventBus.removeListener('set-db-logo', this.setDatabaseLogo);
    }

    optionsExpandedChanged() {
        this.setState({
            optionsExpanded: localStorage.getItem("optionsExpanded") === "1"
        });
    }

    toggleAppBarExpanded() {
        const appBarExpanded = !this.state.appBarExpanded;
        this.setState({
            appBarExpanded
        });
        localStorage.setItem("appBarExpanded", appBarExpanded ? '1' : '0');
    }

    setDatabaseLogo(data) {
        if (data.catalog) {
            if (data.catalog.translated_messages) {
                for (const langCode of Object.keys(data.catalog.translated_messages)) {
                    if (!AppLocale[langCode]) {
                        continue;
                    }
                    for (const key of Object.keys(data.catalog.translated_messages[langCode])) {
                        const translationKey = `db.${data.catalog.code}.${key}`;
                        AppLocale[langCode].messages[translationKey] = data.catalog.translated_messages[langCode][key];
                    }
                }
            }
            if (data.catalog.group && data.catalog.group.translated_messages) {
                for (const langCode of Object.keys(data.catalog.group.translated_messages)) {
                    if (!AppLocale[langCode]) {
                        continue;
                    }
                    for (const key of Object.keys(data.catalog.group.translated_messages[langCode])) {
                        const translationKey = `dbGroup.${data.catalog.group.id}.${key}`;
                        AppLocale[langCode].messages[translationKey] = data.catalog.group.translated_messages[langCode][key];
                    }
                }
            }
        }
        this.setState({logoDatabase: data});
    }

    renderToggleCollapseIcon(drawerStyle) {
        if (this.props.authUser) {
            return this.props.navigationStyle === HORIZONTAL_NAVIGATION ?
                <div className="app-logo-bl">
                    <div className="d-block d-md-none">
                        <span className="jr-menu-icon" onClick={this.onToggleCollapsedNav}>
                            <span className="menu-icon"/>
                        </span>
                    </div>
                </div>
                :
                <span className={`jr-menu-icon pointer ${drawerStyle}`} onClick={this.onToggleCollapsedNav}>
                    <span className="menu-icon"/>
                </span>
        }
        return '';
    }

    render() {
        const {drawerType, locale, navigationStyle, horizontalNavPosition} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-flex d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-flex' : 'd-none';
        return (
            <div className={classNames({"appbar--collapsed": !this.state.appBarExpanded}, "app-main-header")}>
                <div className="d-flex app-toolbar align-items-center">
                    {this.renderToggleCollapseIcon(drawerStyle)}
                    <div className="mx-end-2 d-flex align-items-center topbar-logo-container">
                        {!this.state.logoDatabase && <Switch>
                            <Route
                                path={['/app/database/:id/(dashboard|modify|migration|export|import|service|management-system|audit-log|data-view)', '/app/diagram/database']}
                                component={DatabaseLogo}/>
                            <Route component={DefaultLogo}/>
                        </Switch>}
                        {this.state.logoDatabase && <DatabaseLogo database={this.state.logoDatabase}/>}
                    </div>
                    <ul className="header-notifications list-inline mx-start-auto d-flex align-items-center">
                        {TOGGLE_AUTOMATIC_DEPLOYMENT && this.state.toggleAutomaticDeployEnabled && this.state.optionsExpanded &&
                            <li className="list-inline-item">
                                <ToggleAutomaticDeployment/>
                            </li>}
                        <li className="list-inline-item">
                            <HealthTrafficLight/>
                        </li>
                        <li className="list-inline-item lang-nav">
                            <Dropdown
                                className="quick-menu"
                                isOpen={this.state.langSwitcher}
                                toggle={this.onLangSwitcherSelect.bind(this)}>
                                <DropdownToggle
                                    className="d-inline-block v-align-middle"
                                    tag="span"
                                    data-toggle="dropdown">
                                    <div className="d-flex align-items-center pointer">
                                        <IntlMessages id={`translation.lang.${locale.locale}`}
                                                      defaultMessage={locale.name}/>
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu end className="w-50">
                                    <LanguageSwitcher switchLanguage={this.props.switchLanguage}
                                                      handleRequestClose={this.handleRequestClose}/>
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                        <li className="list-inline-item user-nav pr-0">
                            <Dropdown
                                className="quick-menu"
                                isOpen={this.state.userInfo}
                                toggle={this.onUserInfoSelect.bind(this)}>
                                <DropdownToggle
                                    className="d-inline-block user-circle"
                                    tag="span"
                                    data-toggle="dropdown">
                                    {this.props.authUser && <span className="user-initials-menu-toggler">
                                        {this.getAuthUserInfo('initials')}
                                        <Tooltip placement="left">
                                            <span>{this.getAuthUserInfo('fullname')}</span>
                                            {this.props.authList.length > 0 && <span> ({this.getAuthName()})</span>}
                                        </Tooltip>
                                    </span>}
                                </DropdownToggle>
                                <DropdownMenu className="user-main-menu" end>
                                    <UserInfo/>
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                    </ul>
                    <button className="appbar-collapse" onClick={this.toggleAppBarExpanded}>
                        <i className={classNames({"fa-chevron-up": this.state.appBarExpanded}, {"fa-chevron-down": !this.state.appBarExpanded}, "fa-regular")} />
                    </button>
                </div>
            </div>
        );
    }

    getAuthName() {
        const authId = this.props.authUser.authId ? this.props.authUser.authId : 0;
        const auth = this.props.authList.filter(a => a.id === authId).first;
        return auth ? auth.name : '';
    }

    getAuthUserInfo(info) {
        switch (info) {
            case 'initials':
                return this.props.authUser ? this.props.authUser.firstName.charAt(0) + this.props.authUser.lastName.charAt(0) : '';
            case 'fullname':
                return this.props.authUser ? `${this.props.authUser.firstName} ${this.props.authUser.lastName}` : '';
            default:
                return 'U';
        }
    }

}

const mapStateToProps = ({settings, auth, database}) => {
    const settingsEventBus = settings.eventBus;
    const databaseEventBus = database.eventBus;
    const authUser = auth.authUser;
    const authList = auth.list;
    const {drawerType, locale, navigationStyle, horizontalNavPosition} = settings;
    return {
        drawerType,
        locale,
        navigationStyle,
        horizontalNavPosition,
        authUser,
        authList,
        settingsEventBus,
        databaseEventBus
    }
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, switchLanguage})(Header));