import React from 'react';
import {connect} from 'react-redux'
import {userSignOut} from 'actions/Auth';
import IntlMessages from 'appUtil/IntlMessages';
import {NavLink} from "react-router-dom";
import Helpers from "../../../ts/utils/Helpers";
import {withRouter} from "react-router";
import {stringify} from "query-string";

class UserInfo extends React.Component {
    render() {
        const {authUser} = this.props;
        if (authUser) {
            return (
                <div className={"user-info-menu"}>
                    {/*<div className="user-profile">*/}
                    {/*    <div className="user-detail mx-start-2">*/}
                    {/*        <h4 className="user-name mb-0">{authUser.username}</h4>*/}
                    {/*        <small>{authUser.firstName} {authUser.lastName}</small>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <NavLink to={'/app/profile'} className="dropdown-item text-muted">
                        <i className="zmdi zmdi-face zmdi-hc-fw mx-end-1"/>
                        <IntlMessages id="popup.profile"/>
                    </NavLink>
                    {Helpers.userHasRoles(this.props.authUser, ['super-admin', 'local-translation-update', 'global-translation-update', 'global-translation-publish']) &&
                    <a href={'/#/app/translations'} target={"_blank"} className="dropdown-item text-muted">
                        <i className="zmdi zmdi-translate zmdi-hc-fw mx-end-1"/>
                        <IntlMessages id="popup.translations"/>
                    </a>}
                    {Helpers.userHasRoles(this.props.authUser, ['super-admin', 'data-rights', 'database-rights']) &&
                    <NavLink to={'/app/user-roles/dashboard'} className="dropdown-item text-muted">
                        <i className="zmdi zmdi-accounts-alt zmdi-hc-fw mx-end-1"/>
                        <IntlMessages id="popup.user-management"/>
                    </NavLink>}
                    {Helpers.userHasRoles(this.props.authUser, ['super-admin']) &&
                    <NavLink to={'/app/log'} className="dropdown-item text-muted">
                        <i className="zmdi zmdi-assignment zmdi-hc-fw mx-end-1"/>
                        <IntlMessages id="popup.log"/>
                    </NavLink>}
                    <a className="dropdown-item text-muted" href="#" onClick={(event) => {
                        event.preventDefault();
                        this.props.userSignOut()
                    }}>
                        <i className="zmdi zmdi-sign-in zmdi-hc-fw mx-end-1"/>
                        <IntlMessages id="popup.logout"/>
                    </a>
                </div>
            );
        }
        return (
            <div className={"user-info-menu"}>
                {/*<div className="user-profile">*/}
                {/*    <div className="user-detail mx-start-2">*/}
                {/*        <h4 className="user-name mb-0">*/}
                {/*            <IntlMessages id="guest"/>*/}
                {/*        </h4>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <a className="dropdown-item text-muted" href="#" onClick={(event) => {
                    event.preventDefault();
                    this.props.history.push(`/signin?${stringify({backUrl: window.location.href})}`);
                }}>
                    <i className="zmdi zmdi-sign-in zmdi-hc-fw mx-end-1"/>
                    <IntlMessages id="popup.login"/>
                </a>
            </div>
        )
    }
}

const mapStateToProps = ({auth}) => {
    const authUser = auth.authUser;
    return {authUser}
};

export default withRouter(connect(mapStateToProps, {userSignOut})(UserInfo));


